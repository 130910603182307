import { useEffect, useMemo } from 'react'

import { SelectChangeEvent } from '@mui/material'

import {
  ELEMENT_HEIGHT,
  Entity,
  ILocatie,
  LabelDropdownText,
  useFetchMany,
} from 'src/common'

import { locationToItem } from '../lib'
import { usePurchaserStore } from '../stores'

type LocationDropDownProps = {
  value: string
  name: string
  onChange?: (event: SelectChangeEvent<string>) => void
}
export default function LocationDropDown({
  name,
  value,
  onChange,
}: Readonly<LocationDropDownProps>): JSX.Element {
  const ENABLED = true as const
  const { setLocations } = usePurchaserStore()

  const {
    data: { records: locations },
  } = useFetchMany<ILocatie>(undefined, Entity.ILocatie, ENABLED, {
    staleTime: Infinity,
    suspense: true,
  })

  const items = useMemo(() => locations.map(locationToItem), [locations])

  useEffect(() => {
    setLocations(locations)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations])

  return (
    <LabelDropdownText
      fieldName={name}
      items={items}
      value={items.length > 0 ? value : ''}
      onChange={onChange}
      sx={{
        maxHeight: ELEMENT_HEIGHT,
        mt: 0,
        fontSize: '1em',
        fontWeight: 'normal',
      }}
    />
  )
}
