import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Stack } from '@mui/material'

import { useStore } from 'zustand'

import { ELEMENT_HEIGHT, isDateToday, LabelDate, ThemeConfig } from 'src/common'
import { useDevToolsStore } from 'src/dev-tools'
import { FEATURES } from 'src/dev-tools/config'
import {
  isInputValid,
  usePurchaserLimit,
  usePurchaserLimitsStore,
} from 'src/features/purchaserLimits'

import { usePurchaserStore } from '../../stores'
import { FormInput } from './FormInput'

export default function LimitFields(): JSX.Element {
  const { t } = useTranslation()
  const purchaser = useStore(usePurchaserStore, value => value.purchaser)
  const { TODAY, TOMORROW, NEVER } = usePurchaserLimit()
  const shouldFeatureBeEnabled = useStore(
    useDevToolsStore,
    state => state.shouldFeatureBeEnabled
  )
  const {
    dayLimit,
    isPurchaserLimitAmountChanged,
    oldDayLimit,
    updateDayLimit,
  } = usePurchaserLimitsStore()

  const changeLimit = useCallback(
    (event: any) => {
      const { value } = event.target
      if (!isInputValid('inkoperLimiet', value)) return
      const resValue = value.trim() ? Number(value) : 0
      updateDayLimit({ userAccountDayLimitAmount: resValue })
    },
    [updateDayLimit]
  )

  const changeLimitEndDate = useCallback(
    (date: Date) => {
      if (!isInputValid('endDate', date)) return
      const newDayLimit = { ...dayLimit }
      if (isDateToday(date)) {
        newDayLimit.endDateTime = oldDayLimit.endDateTime
      }
      newDayLimit.endDateTime = date
      updateDayLimit(newDayLimit)
    },
    [dayLimit, oldDayLimit.endDateTime, updateDayLimit]
  )

  return (
    <Stack gap={ThemeConfig.spacing.s}>
      {/*InkoperLimiet */}
      <FormInput
        label={t('purchaser.dayLimitAmount')}
        name={'inkoperLimiet'}
        defaultValue={0}
        maxLength={6}
        isNumber
        value={dayLimit?.userAccountDayLimitAmount ?? 0}
        disabled={
          !purchaser?.inkoperID ||
          purchaser?.inkoperID === 0 ||
          !shouldFeatureBeEnabled(FEATURES.INKOPERLIMIETEN) ||
          isDateToday(dayLimit?.endDateTime)
        }
        onChange={changeLimit}
      />
      {/* Enddate */}
      <LabelDate
        fieldName='limietEindDatum'
        defaultValue={NEVER}
        value={new Date(dayLimit?.endDateTime)}
        label={t('purchaser.dayLimitEndDateTime')}
        flowDirection='row'
        minDate={
          Number(dayLimit?.userAccountDayLimitAmount) > 0 &&
          !isPurchaserLimitAmountChanged()
            ? TODAY
            : TOMORROW
        }
        disabled={
          !purchaser?.inkoperID ||
          purchaser?.inkoperID === 0 ||
          !shouldFeatureBeEnabled(FEATURES.INKOPERLIMIETEN)
        }
        onChange={changeLimitEndDate}
        sx={{
          fontFamily: 'Halyard-text',
          fontWeight: 'normal',
          maxHeight: ELEMENT_HEIGHT,
          pr: 0.5,
        }}
      />
    </Stack>
  )
}
