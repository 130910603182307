import { ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import Checkbox from '@mui/material/Checkbox'
import Stack from '@mui/material/Stack'

import { showDateTime } from 'src/common'
import { ThemeConfig } from 'src/common/config/SpacingConfig'

import { usePurchaserStore } from '../../stores'
import { FormInput } from './FormInput'

export default function BlockingFields(): JSX.Element {
  const { t } = useTranslation()
  const { purchaser, updatePurchaser } = usePurchaserStore()

  const changeBlockedIndicator = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      updatePurchaser({ blokkeerIndicatie: event.target.checked })
    },
    [updatePurchaser]
  )

  const changeRemark = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      updatePurchaser({ opmerking: event.target.value })
    },
    [updatePurchaser]
  )

  return (
    <Stack gap={ThemeConfig.spacing.s}>
      {/* geblokkeerd */}
      <FormInput label={t('purchaser.blocked')}>
        <Checkbox
          checked={purchaser?.blokkeerIndicatie ?? false}
          onChange={changeBlockedIndicator}
          sx={{ marginLeft: -3.3, marginTop: -1.5 }}
        />
      </FormInput>
      {/* reden */}
      <FormInput
        label={t('purchaser.blockingReason')}
        name={'blokkeerRedenOmschrijving'}
        value={
          purchaser?.blokkeerIndicatie
            ? purchaser?.blokkeerRedenOmschrijving || t('common.unspecified')
            : t('common.notApplicable')
        }
        disabled
      />
      {/* blokkeren datumTijd */}
      <FormInput
        label={t('purchaser.blockingDateTime')}
        name={'blokkerenDatumTijd'}
        value={showDateTime(purchaser?.blokkerenDatumTijd)}
        disabled
      />
      {/* vrijgave datumTijd */}
      <FormInput
        label={t('purchaser.unblockingDateTime')}
        name={'vrijgaveDatumTijd'}
        value={showDateTime(purchaser?.vrijgaveDatumTijd)}
        disabled
      />
      {/* opmerking */}
      <FormInput
        label={t('common.remark')}
        name={'opmerking'}
        value={purchaser?.opmerking}
        onChange={changeRemark}
      />
      <FormInput
        label={t('purchaser.discardDateTime')}
        name={'vervallenDatumTijd'}
        value={showDateTime(purchaser?.vervallenDatumTijd)}
        disabled
      />
    </Stack>
  )
}
